<template>
    <ion-page>
        <ion-content>
            <div class="desktop-panel">
                <img alt="Sportspoule patroon"
                     class="auth-header-image"
                     src="@/assets/images/auth-header-image.svg">
                <div class="auth-intro-header">
                    <img alt="Sportspoule logo"
                         class="logo"
                         src="@/assets/images/sportspoule-logo.svg">
                    <p v-if="groupName">
                        {{ $t('auth.group-shared-auth.invited-for') }} <br>
                        <span class="blue">{{ groupName }}</span>
                    </p>
                    <p v-if="description" style="margin-bottom: -100px;">
                        {{ description }}
                    </p>
                </div>
                <div :class="{'less-padding-top': groupName}" class="actions">
                    <sign-in-with-button driver="apple" />
                    <sign-in-with-button driver="google" />
                    <sign-in-with-button driver="facebook" />
                    <or-divider />
                    <div class="btn white"
                         @click="goToRegister">
                        {{ $t('auth.get-started.register') }}
                    </div>
                    <div class="btn transparant"
                         @click="goToLogin">
                        {{ $t('auth.get-started.login') }}
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script lang="ts" setup>
    import {
        IonPage,
        IonContent,
    } from '@ionic/vue'
    import OrDivider from "@/components/OrDivider.vue";
    import SignInWithButton from "@/components/SignInWithButton.vue";
    import { useRouter } from 'vue-router';
    import { onMounted, ref } from 'vue';
    import StorageService from '@/services/storage.service';
    import GroupService from '@/services/group.service';
    import { Haptics, ImpactStyle } from "@capacitor/haptics";

    const router = useRouter();

    const groupName = ref(null);
    const description = ref<string|null>(null);

    const goToRegister = () => {
        Haptics.impact({ style: ImpactStyle.Medium });
        router.push({ name: 'auth.register' });
    }

    const goToLogin = () => {
        Haptics.impact({ style: ImpactStyle.Medium });
        router.push({ name: 'auth.login' });
    }

    onMounted(async () => {
        if (typeof router.currentRoute.value.query.redirect === 'string') {
            await StorageService.set('intended_url', router.currentRoute.value.query.redirect)
        }
        if (typeof router.currentRoute.value.query.description === 'string') {
            description.value = router.currentRoute.value.query.description;
        }
        const intendedUrl: string = await StorageService.get('intended_url');
        if (intendedUrl?.includes('poules/share')) {
            const groupToken = intendedUrl.split('/').filter(Boolean).pop();
            if (groupToken) {
                groupName.value = await GroupService.getPouleNameByShareLink(groupToken);
            }
        }
    });
</script>

<style lang="scss" scoped>
    .auth-intro-header {
        position        : relative;
        display         : flex;
        flex-direction  : column;
        justify-content : center;
        padding         : 168px 24px 0 24px;

        .logo {
            margin-bottom : vw(40px);
            height        : vw(40px);
        }

        p {
            font-size   : 18px;
            font-weight : 600;
            line-height : 25.2px;
            text-align  : center;
        }

        .blue {
            color : var(--color-primary);
        }
    }

    .actions {
        display        : flex;
        flex-direction : column;
        padding        : vw(136px) 32px 48px 32px;

        &.less-padding-top {
            padding-top : vw(24px);
        }

        .btn {
            margin-bottom : 16px;
        }
    }
</style>

